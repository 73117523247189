@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
}
@media (min-width: 700px) {
  .phone {
      display: none;
  }
}
